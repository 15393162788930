<template>
  <div class="system-build">
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/SystemBuild1.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p>服务介绍</p>
        </div>
        <p
          class="Rem0_24 animate__animated"
          v-scroll="'animate__fadeIn'"
          style="animation-delay: 1s"
        >
          景翔拥有近三十年管理咨询经验，辅导过上千家企业搭建其管理体系，积累了丰富实战经验，并形成一套成熟的体系管理方法论。
          我们一直致力于为企业搭建围绕战略目标的治理体系，主张建立以核心业务为中心的管理模式，通过业务流程识别、展开、分解，再制定标准和责任人。通过对核心业务流程的拉通和优化，解决了传统体系与战略脱钩、流程滞后、权责不明、信息孤岛等问题。
        </p>
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p>体系搭建的服务：</p>
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list2"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="number2">
      <div
        class="Rem0_4 animate__animated"
        v-scroll="'animate__fadeInDown'"
        style="animation-delay: 1s"
      >
        <p style="color: #4375c8">管理层对业务流的苦恼</p>
        <p></p>
      </div>
      <div class="contain animate__animated" v-scroll="'animate__fadeInLeft'">
        <img src="@assets/images/SystemBuild4.png" alt="" />
        <img src="@assets/images/SystemBuild3.png" alt="" />
        <div>
          <div style="background: #e3e7f9">
            <p class="Rem0_25">管理体系文件分类</p>
            <p class="Rem0_19">
              体系层级多，文件多、没有人知道与自己有关的文件有多少
            </p>
          </div>
          <div>
            <p class="Rem0_25">流程落地难</p>
            <p class="Rem0_19">
              流程过长、信息流过长，导致很多内容重复，不够敏捷.落地困难
            </p>
          </div>
        </div>
        <div>
          <div>
            <p class="Rem0_25">流程接口不清</p>
            <p class="Rem0_19">
              流程节点不清、接口不清导致跨部门跨协同困难、效率低下
            </p>
          </div>
          <div>
            <p class="Rem0_25">评价困难</p>
            <p class="Rem0_19">
              流程分工和权责不清、工作要求不清，导致绩效无法衡量和评价
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="number3">
      <img src="@assets/images/SystemBuild6.png" width="100%" />
      <div class="contain">
        <div>
          <div
            class="Rem0_4 animate__animated"
            v-scroll="'animate__fadeIn'"
            style="animation-delay: 1s"
          >
            <p style="color: #4375c8">我们需要怎么样的管理体系?</p>
            <p></p>
          </div>
          <p
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInUpBig'"
          >
            以“价值驱动”为中心的流程体系
          </p>
          <div class="text animate__animated" v-scroll="'animate__fadeInUpBig'">
            <p class="Rem0_19"><span></span>为企业带来“可视化”管理地图</p>
            <p class="Rem0_19">
              <span></span>为流程提供“结构化”和“一体化”顶层管理方法
            </p>
            <p class="Rem0_19"><span></span>为企业提供统一的流程语言</p>
          </div>
        </div>
        <div class="right">
          <div
            class="Rem0_4 animate__animated"
            v-scroll="'animate__fadeIn'"
            style="animation-delay: 2s"
          >
            <p style="color: #4375c8">管理及体系的现状与未来</p>
            <p></p>
          </div>
          <img
            class="text animate__animated"
            v-scroll="'animate__fadeInDownBig'"
            src="@assets/images/SystemBuild5.png"
            style="animation-delay: 1s"
          />
        </div>
      </div>
    </div>
    <div class="number4">
      <div>
        <img
          class="text animate__animated"
          v-scroll="'animate__fadeInLeftBig'"
          src="@assets/images/SystemBuild7.png"
          alt=""
        />
        <div
          class="text animate__animated"
          v-scroll="'animate__fadeInRightBig'"
        >
          <div class="Rem0_4">
            <p style="color: #4375c8">搭建围绕战略目标的管理体系</p>
            <p></p>
          </div>
          <p class="Rem0_25">
            我们通过对战略目标的分解，识别实现战略目标所需的业务流程，重新检讨部门职能与制定KPI，建立一套以实现战略目标为目的的经营管理体系。
          </p>
        </div>
      </div>
    </div>
    <div class="number5">
      <div class="Rem0_4 animate__animated" v-scroll="'animate__bounceInUp'">
        <p style="color: #4375c8">我司特有的新型价值链模型</p>
        <p></p>
      </div>
      <p class="Rem0_25 animate__animated" v-scroll="'animate__bounceInUp'">
        新型价值是链是为顾客打造的一条从市场始端到交付端创造价值的全过程路线图，它综合了波特价值链思想国际公认的过程方法、丰田的精益价值流、价值网络分析技术以及数字化技术的一种创新型工具，可以为企业大幅缩短设计、采购、生产交付等周期。可以为企业带来“可视化”管理地图，显著提升企业的竞争力；为企业管理体系的数字化转型打下坚实的基础。
      </p>
      <img
        class="Rem0_25 animate__animated"
        v-scroll="'animate__bounceInUp'"
        src="@assets/images/SystemBuild8.png"
        alt=""
      />
    </div>
    <div class="number6">
      <div class="Rem0_4 animate__animated" v-scroll="'animate__bounceIn'">
        <p style="color: #4375c8">以新型价值链为核心的数字化管理体系模型</p>
        <p></p>
      </div>
      <div class="contain">
        <div class="animate__animated" v-scroll="'animate__bounceInLeft'">
          <div>
            <p class="Rem0_25">以“价值驱动”为中心的流程体系</p>
            <p class="Rem0_19">流程体系是企业顶层规划与各种标准落地的底座</p>
          </div>
          <div>
            <p class="Rem0_25"><span></span>第一步：流程体系顶层设计</p>
            <p class="Rem0_19">
              基于职能分解与新型价值链塑造企业的流程能力与端到端的业务主线
            </p>
          </div>
          <div>
            <p class="Rem0_25"><span></span>第二步：流程优化</p>
            <p class="Rem0_19">
              上承顶层设计，运用过程方法进行活动的识别与分解，构建组织、流程与过程绩效的协同机制
            </p>
          </div>
          <div>
            <p class="Rem0_25"><span></span>第三步：数字落地</p>
            <p class="Rem0_19">通过数字化技术将流程体系要求落地</p>
          </div>
        </div>
        <img
          class="animate__animated"
          v-scroll="'animate__bounceInRight'"
          src="@assets/images/SystemBuild9.png"
          alt=""
        />
      </div>
    </div>
    <div class="number7">
      <div class="Rem0_4 animate__animated" v-scroll="'animate__zoomIn'">
        <p style="color: #4375c8">成功案例</p>
        <p></p>
      </div>
      <div>
        <div class="animate__animated" v-scroll="'animate__rollIn'">
          <p class="Rem0_25">某地产集团</p>
          <p class="Rem0_19">
            某地产集团总部某地产集团总部位于上海，是...
            某地产集团总部位于上海，是... 位于上海，是...
          </p>
          <img src="@assets/images/SystemBuild2.png" alt="" />
        </div>
        <div class="animate__animated" v-scroll="'animate__rollIn'">
          <p class="Rem0_25">某地产集团</p>
          <p class="Rem0_19">
            某地产集团总部某地产集团总部位于上海，是...
            某地产集团总部位于上海，是... 位于上海，是...
          </p>
          <img src="@assets/images/SystemBuild2.png" alt="" />
        </div>
        <div class="animate__animated" v-scroll="'animate__rollIn'">
          <p class="Rem0_25">某地产集团</p>
          <p class="Rem0_19">
            某地产集团总部某地产集团总部位于上海，是...
            某地产集团总部位于上海，是... 位于上海，是...
          </p>
          <img src="@assets/images/SystemBuild2.png" alt="" />
        </div>
        <div class="animate__animated" v-scroll="'animate__rollIn'">
          <p class="Rem0_25">某地产集团</p>
          <p class="Rem0_19">
            某地产集团总部某地产集团总部位于上海，是...
            某地产集团总部位于上海，是... 位于上海，是...
          </p>
          <img src="@assets/images/SystemBuild2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="number8">
      <div class="contain animate__animated" v-scroll="'animate__fadeIn'">
        <p class="title Rem0_28">某地产集团</p>
        <div class="imgbox">
          <p class="Rem0_25">
            某地产集团总部位于上海，是一家聚焦于住宅开发、商业运营等房地产上下游产业链的大型综合企业，2023年位列中国房地产开发企业TOP50.当时由于该地产集团高管曾经在龙湖集团任职十分认可我司建立体系和流程梳理的方法。希望应用此方法帮助集团下的地产和物业板块建立一套适合东原实际情况管控体系，并且帮助地产建立其价值流，以优化他们的核心业务流。
          </p>
          <img src="@assets/images/SystemBuild2.png" alt="" />
        </div>
        <p class="title Rem0_28">项目成果</p>
        <div class="ul">
          <div>
            <div class="Rem0_25">
              <p></p>
              制定企业管理核心流程图
            </div>
            <div class="Rem0_25">
              <p></p>
              根据战略制定目标管理体系
            </div>
            <div class="Rem0_25">
              <p></p>
              识别和建立相关流程，并使流程合理和畅顺
            </div>
          </div>
          <div>
            <div class="Rem0_25">
              <p></p>
              理顺部门间的接口和相互关系
            </div>
            <div class="Rem0_25">
              <p></p>
              理顺岗位职责和部门职能，以提高工作效率
            </div>
            <div class="Rem0_25">
              <p></p>
              确保管理体系符合IS09001的要求
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import animateScroll from "../../../modules/AnimateScroll.js";
export default {
  data() {
    return {
      serveList: {
        list1: [
          "构建经营路线图",
          "部门职能分解",
          "构建新型价值链",
          "构建体系树",
          "构建合理的目标指标",
          "构建体系信息流",
        ],
        list2: [
          "管理体系文件分类",
          "流程素数字化",
          "表单数字化",
          "构建数据链",
          "体系文件网格化",
          "智能制造体系构建",
        ],
      },
    };
  },
  animateScroll,
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/Media.css";
.system-build {
  background: #ffffff;

  @media screen and (min-width: 1200px) and (max-width: 1519px) {
    .number1 {
      .Rem0_4 {
        padding-top: 20px !important;
      }
    }
    .number3 {
      .right {
        img {
          width: 5.2rem !important;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .number1 {
      .Rem0_4 {
        padding-top: 0px !important;
      }
      .ul span {
        margin: 5px 2px !important;
      }
    }
    .number2 {
      img:nth-of-type(1) {
        width: 1.8rem !important;
        top: -30% !important;
      }
      img:nth-of-type(2) {
        width: 3.5rem !important ;
        top: 15% !important;
      }
    }
    .number3 {
      .right {
        .Rem0_4 p:nth-of-type(2) {
          left: 0.35rem !important;
        }
        img {
          width: 4.5rem !important;
        }
      }
    }
    .number7 {
      div {
        div {
          width: 200px !important;
          padding: 15px 25px !important;
          img {
            width: 200px !important;
            height: 120px !important;
          }
        }
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .number1 {
      .Rem0_4 {
        padding-top: 0px !important;
      }
      .ul span {
        margin: 3px 2px !important;
      }
    }
    .number7 {
      div {
        div {
          width: 150px !important;
          padding: 10px 15px !important;
          img {
            width: 150px !important;
            height: 90px !important;
          }
        }
      }
    }
  }

  .number1 {
    position: relative;
    height: 100vh;
    img {
      position: absolute;
    }
    .contain {
      width: 80%;
      margin: 0 10%;
      position: absolute;
      top: 0;
      color: white;
      text-align: start;
      .Rem0_4:nth-of-type(1) {
        display: flex;
        justify-content: center;
      }
      .Rem0_4:nth-of-type(2) {
        margin-top: 40px;
      }
      p:nth-of-type(1) {
        padding-top: 30px;
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin: 0 6px;
          margin-top: 20px;
          background: linear-gradient(to right, #555974, #5D616F);
          padding: 0.18rem 0;
          cursor: default;
        }
      }
    }
  }
  .number2 {
    position: relative;
    height: 100vh;
    .Rem0_4 {
      text-align: center;
      padding-top: 40px;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .contain {
      display: flex;
      justify-content: center;
      margin-top: 8%;
      position: relative;
      width: 70% !important;
      margin-left: 15% !important;
      margin-right: 15% !important;
      img {
        position: absolute;
      }
      img:nth-of-type(1) {
        top: -37%;
        left: 0.25rem;
        width: 2.1rem;
      }
      img:nth-of-type(2) {
        width: 4rem;
        top: 13%;
        z-index: 99999;
      }
      > div {
        flex: 1;
        z-index: 9999;
        div {
          background: #f4f2f7;
          margin: 0.25rem;
          padding: 0.35rem 0.4rem 0.55rem 0.4rem;
          .Rem0_25 {
            font-weight: bold;
          }
          .Rem0_19 {
            width: 50%;
          }
        }
      }
      > div:nth-of-type(2) {
        text-align: end;
        .Rem0_19 {
          display: inline-block;
          margin-right: 0px;
        }
      }
    }
  }
  .number3 {
    position: relative;
    height: 100vh;
    > img {
      position: absolute;
    }
    .contain {
      width: 70%;
      padding-top: 5%;
      margin: 0 15%;
      display: flex;
      justify-content: space-between;
      .Rem0_25 {
        margin: 15% 0 5% 0;
        color: #20517d;
        font-weight: bold;
      }
      .Rem0_19 {
        padding-top: 10px;
        color: #28284b;
        display: flex;
        align-items: center;
        span:nth-of-type(1) {
          margin-right: 10px;
          background-color: #838bf6;
          border-radius: 50%;
          color: #838bf6;
          display: inline-block;
          width: 0.2rem;
          height: 0.2rem;
        }
      }
      div {
        z-index: 999;
      }
      .right {
        padding-top: 15%;
        text-align: center;
        .Rem0_4 {
          p:nth-of-type(2) {
            left: 0.64rem;
          }
        }
        img {
          width: 5.5rem;
          margin-top: 15%;
        }
      }
    }
  }
  .number4 {
    background: #f7fafd;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 80%;
      display: inline-flex;
      img {
        width: 55%;
        height: 55%;
      }
      > div {
        padding-left: 30px;
        padding-top: 40px;
        text-align: start;
        .Rem0_25 {
          margin-top: 17%;
          width: 80%;
        }
      }
    }
  }
  .number5 {
    background: #ffffff;
    height: 100vh;
    margin: 0 12%;
    text-align: center;
    .Rem0_4 {
      margin-top: 5%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .Rem0_25 {
      margin-top: 5%;
      text-align: start;
    }
    img {
      width: 80%;
      margin-top: 3%;
    }
  }
  .number6 {
    background: #f7fafd;
    height: 100vh;
    .Rem0_4 {
      padding-top: 5%;
      text-align: center;

      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .contain {
      display: flex;
      justify-content: center;
      margin-top: 5%;
      color: #19193e;
      div {
        margin: 0 0 3.5% 0;
        color: #19193e;
        .Rem0_25 {
          display: flex;
          align-items: center;
          span:nth-of-type(1) {
            margin-right: 10px;
            background-color: #838bf6;
            border-radius: 50%;
            color: #838bf6;
            width: 0.25rem;
            display: inline-block;
            height: 0.25rem;
          }
        }
        .Rem0_19 {
          width: 75%;
          padding-left: 5%;
          line-height: 0.3rem !important;
        }
        div:nth-of-type(1) .Rem0_19 {
          padding-left: 0 !important;
        }
      }
      img {
        width: 35%;
        height: 100%;
      }
    }
  }
  .number7 {
    text-align: center;
    .Rem0_4 {
      margin-top: 5%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      padding-bottom: 5%;
      padding-top: 5%;
      div {
        width: 250px;
        margin: 10px;
        padding: 20px 35px;
        border-radius: 10px;
        background-color: #f6f6f6;
        .Rem0_25 {
          line-height: 0.35rem !important;
        }
        .Rem0_19 {
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; // 多行在这里修改数字即可，这里显示2行
          overflow: hidden;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }
        img {
          width: 250px;
          height: 150px;
          border-radius: 10px;
        }
      }
      div:hover {
        background: #24569c;
        color: white !important;

        .Rem0_19 {
          color: white !important;
        }
      }
    }
  }
  .number8 {
    background: #214e8e;
    color: white;
    padding-bottom: 5%;
    .Rem0_28 {
      line-height: 0.8rem !important;
      margin-top: 0.2rem;
    }
    .contain {
      margin: 0 10%;
      padding-top: 20px;
      .imgbox {
        display: flex;
        margin-top: 10px;
        .Rem0_25 {
          width: 50%;
          margin-right: 40px;
        }
        img {
          width: 50%;
        }
      }
      .ul {
        display: flex;
        > div:nth-of-type(1) {
          margin-right: 50px;
        }
        .Rem0_25 {
          display: flex;
          align-items: center;
          p {
            height: 10px;
            width: 10px;
            transform: rotateZ(45deg);
            background: white;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>